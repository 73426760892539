import {
  Container, AnimatedSprite, Texture, Sprite, Graphics,
} from 'pixi.js';
import { assetsMap } from './assetsMap';
import { getAssetByName } from './assetsAccessor';

const container = document.querySelector('[data-game-container]');

let containerWidth = 0;

if (container) {
  containerWidth = container.parentElement.clientWidth;
}

export const createSprite = (
  textureName,
  position = { x: 0, y: 0 },
  anchor = { x: 0.5, y: 0.5 },
  spriteHeight,
  spriteWidth,
) => {
  if (!container) {
    return null;
  }
  const sprite = new Sprite(Texture.from(textureName));
  sprite.position.copyFrom(position);
  sprite.anchor.copyFrom(anchor);
  sprite.height = spriteHeight;
  sprite.width = spriteWidth;
  return sprite;
};

export class Sprites {
  spriteByIndex(index) {
    if (!container) {
      return null;
    }
    switch (index) {
      case 0:
        this._block1 = createSprite(
          getAssetByName('word1', 'words', assetsMap).url,
          { x: containerWidth, y: 0 }, { x: 0.5, y: 0.5 },
          43, 87,
        );

        return this._block1;

      case 1:
        this._block2 = createSprite(
          getAssetByName('word2', 'words', assetsMap).url,
          { x: containerWidth, y: 0 },
          { x: 0.5, y: 0.5 },
          43, 97,
        );

        return this._block2;
      case 2:
        this._block3 = createSprite(
          getAssetByName('word3', 'words', assetsMap).url,
          { x: containerWidth, y: 0 },
          { x: 0.5, y: 0.5 },
          43, 106,
        );

        return this._block3;
      default:
        this._block1 = createSprite(
          getAssetByName('word1', 'words', assetsMap).url,
          { x: containerWidth, y: 0 }, { x: 0.5, y: 0.5 },
          43, 87,
        );

        return this._block1;
    }
  }
}