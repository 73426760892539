import {onDomReady} from "../../components/dynamic/observer";

function initMapYandex() {
  ymaps.ready(() => {
    document.querySelectorAll('[data-map]').forEach(function initMapElement(mapElement) {
      let myMap = new ymaps.Map(mapElement, {
        center: [parseFloat(mapElement.dataset.lat), parseFloat(mapElement.dataset.lng)],
        zoom: mapElement.dataset.zoom,
        controls: ['zoomControl', 'fullscreenControl'],
      }, {
        searchControlProvider: 'yandex#search',
      });

      let myPlacemark;

      if (window.innerWidth > 568) {
        myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
          iconLayout: 'default#image',
          iconImageHref: mapElement.dataset.mark,
          iconImageSize: [62, 74],
          iconImageOffset: [0, -74],
        });
      } else {
        myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
          iconLayout: 'default#image',
          iconImageHref: mapElement.dataset.mark,
          iconImageSize: [42, 48],
          iconImageOffset: [0, -48],
        });
      }

      myMap.behaviors.disable('scrollZoom');
      if (window.innerWidth <= 1024) {
        myMap.behaviors.disable('drag');
      }
      myMap.geoObjects.add(myPlacemark);
    });
  });
}

const initMapPending = () => {
  if (window.ymaps) {
    initMapYandex();
    return;
  }
  setTimeout(() => {
    initMapPending();
  }, 500);
};

onDomReady(() => initMapPending());