import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

class TextWrapCreator {
    container = undefined;
    element = undefined;
    classes = [];
    originalString = undefined;
    storeString = undefined;
    storeStringInner = undefined;
    words = [];
    line = undefined;

    constructor(selector) {
        this.element = selector;
        this.container = this.element.parentElement;
        this.classes = this.element.classList;
        this.originalString = this.element.innerHTML.trim();
        this.container.classList.add('_text-wrap-creator-container');
        this.words = this.originalString.split(' ');
        this.line = '';
        this.initStoreString();
        this.create();
        this.eventListeners();
    }

    initStoreString() {
        this.storeString = document.createElement('span');
        this.storeStringInner = document.createElement('span');
        this.classes.forEach((className) => this.storeString.classList.add(className));
        this.storeString.style.opacity = '0';
        this.storeString.style.position = 'absolute';
        this.storeString.style.visibility = 'hidden';
        this.storeString.append(this.storeStringInner);
        this.container.append(this.storeString);
    }

    create() {
        let isLastWord = false;

        for (let n = 0; n < this.words.length; n++) {

            let testLine = this.line + this.words[n] + ' ';
            this.storeStringInner.innerHTML = testLine;

            isLastWord = n === this.words.length - 1;
            if (this.storeStringInner.offsetWidth > this.storeString.offsetWidth && n > 0) {

                let lastIndex = testLine.lastIndexOf(this.words[n]);
                testLine = testLine.substring(0, lastIndex);

                this.createNewElement(testLine);
                this.line = this.words[n] + ' ';

                if (isLastWord) {
                    this.createNewElement(this.line);
                }

            } else if (isLastWord) {
                this.createNewElement(testLine);
            } else {
                this.line = testLine;
            }
        }

        this.line = '';
        this.element.remove();
    }

    createNewElement(testLine) {
        const newElement = document.createElement('span');
        const newElementInner = document.createElement('span');
        newElement.append(newElementInner);
        this.classes.forEach(className => newElement.classList.add(className));
        if (this.element.dataset.textInnerClass) {
            newElementInner.className = this.element.dataset.textInnerClass;
        }
        newElementInner.style.display = 'block';
        newElementInner.innerHTML = testLine;
        this.container.append(newElement);
    }

    removeAllElements() {
        while (this.container.lastChild !== this.storeString) {
            this.container.removeChild(this.container.lastChild);
        }
    }

    eventListeners() {
        window.addEventListener('resize', () => {
            this.removeAllElements();
            this.create();
        });
    }
}

async function isReady() {
    let ready = await document.fonts.ready;
}

function init() {
    isReady().then(r => document.querySelectorAll('[data-text-wrap]:not([data-initialized="true"])')
        .forEach((item) => {
            item.dataset.initialized = 'true';
            const textWrapCreator = new TextWrapCreator(item);
        }));
}

onDomReady(() => init());
onDomChanges(() => init());