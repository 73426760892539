import {
  Controller, Navigation, Pagination, Swiper, FreeMode,
} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';
import sliderCounter from "./common/sliderCounter";

onDomReady(() => {
  const containers = document.querySelectorAll('[data-agency-slider-container]');

  containers.forEach((container) => {
    if (container) {
      const imageSliderEl = container.querySelector('[data-agency-image-slider]');
      const counterEl = container.querySelector('[data-agency-slider-counter]');

      let imageSlider;
      let counterSlider;

      const countEl = container.querySelector('[data-agency-slider-current]');
      const totalEl = container.querySelector('[data-agency-slider-total]');
      const leftArrow = container.querySelector('[data-agency-left]');
      const rightArrow = container.querySelector('[data-agency-right]');
      const progress = container.querySelector('[data-agency-slider-progress]');

      if (imageSliderEl) {
        imageSlider = new Swiper(imageSliderEl, {
          modules: [Controller, Navigation, Pagination, FreeMode],
          slidesPerView: 'auto',
          freeMode: true,
          // speed: 1500,
          // preventInteractionOnTransition: true,
          navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
          },
          pagination: {
            el: progress,
            type: 'progressbar',
          },
        });
      }

      sliderCounter(imageSlider, countEl, totalEl);

      // TODO: проверить работу счетчика при переключении слайдов: с фримодом он работает не так как надо: не синхронно
      // if (counterEl) {
      //   counterSlider = new Swiper(counterEl, {
      //     speed: 1500,
      //     slidesPerView: 1,
      //     direction: 'vertical',
      //     preventInteractionOnTransition: true,
      //   });
      // }

      // imageSlider.on('slideChange', () => {
      //   counterSlider.slideTo(imageSlider.realIndex, 1500);
      // });
    }
  });
});