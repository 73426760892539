class TextRepetitionObject {
    constructor(selector) {
        this.element = selector;
        this.items = this.element.querySelectorAll('[data-text-repetition-item]');
    }

    computedStyles() {
        const instance = this;
        const elementRect = this.element.getBoundingClientRect();
        const elementTop = elementRect.top;
        const elementBottom = elementTop + elementRect.height;

        if (elementBottom < 0 || elementTop > window.innerHeight) {
            // элемент выше или ниже экрана
            instance.ratio = 0;
        } else {
            instance.ratio = (window.innerHeight - elementTop) / window.innerHeight;
        }
    }

    setStyles() {
        const instance = this;
        instance.items.forEach((item, index) => {
            const offset = (instance.ratio * 20) * (index + 1);
            const zIndex = (instance.items.length + 1) - (index + 1);
            item.style.zIndex = `${zIndex}`;
            item.style.transform = `translate3d(0, -${offset}%, 0)`;
        })
    }

    update(offset) {
        const instance = this;
        instance.offset = offset;
        instance.computedStyles();
        instance.setStyles();
    }
}

export default TextRepetitionObject;