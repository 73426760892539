import {
  Container, AnimatedSprite, Texture, Sprite, Graphics, Polygon, Rectangle, Filter, filters, Ticker,
} from 'pixi.js';
import { AnimatedGIF } from '@pixi/gif';
import { assetsMap } from './assetsMap';
import { getAssetByName } from './assetsAccessor';

export const createGif = (
  textureName,
  position = { x: 0, y: 0 },
  anchor = { x: 0.5, y: 0.5 },
) => fetch(textureName)
  .then((res) => res.arrayBuffer())
  .then(AnimatedGIF.fromBuffer);

export const createSprite = (
  textureName,
  position = { x: 0, y: 0 },
  anchor = { x: 0.5, y: 0.5 },
) => {
  const sprite = new Sprite(Texture.from(textureName));
  sprite.position.copyFrom(position);
  sprite.anchor.copyFrom(anchor);
  return sprite;
};

export class Car {
  constructor() {
    // eslint-disable-next-line no-underscore-dangle
    this._view = new Container();

    // eslint-disable-next-line no-underscore-dangle
    this._carContainer = new Container();
    this._gifContainer = new Container();
    this._wheelContainer = new Container();
    this._view.addChild(this._carContainer);
    this._view.addChild(this._gifContainer);
    this._view.addChild(this._wheelContainer);

    this._car = createSprite(getAssetByName('car', 'sprites', assetsMap).url, { x: 0, y: -32 }, { x: 0.5, y: 1 });
    this._car._height = 112;
    this._car._width = 255;

    this._brokenCar = createSprite(getAssetByName('broken-car', 'sprites', assetsMap).url, { x: 0, y: -32 }, {
      x: 0.5,
      y: 1,
    });
    this._brokenCar._height = 112;
    this._brokenCar._width = 255;

    this._mask = new Graphics();
    this._mask.beginFill(0xFF0000);
    this._mask.drawRoundedRect(-127, -144, 255, 112, 100);
    this._mask.endFill();

    this._car.mask = this._mask;
    this._brokenCar.mask = this._mask;

    this._carContainer.addChild(this._car, this._mask);

    this._wheelFront = createSprite(getAssetByName('wheel-front', 'sprites', assetsMap).url, { x: 65, y: 0 }, {
      x: 0.5,
      y: 0.5,
    });
    this._wheelFront._height = 45;
    this._wheelFront._width = 45;
    this._wheelFront.rotation = 0.2;

    this._wheelRear = createSprite(getAssetByName('wheel-rear', 'sprites', assetsMap).url, { x: -65, y: 0 }, {
      x: 0.5,
      y: 0.5,
    });
    this._wheelRear._height = 45;
    this._wheelRear._width = 45;

    this._wheelContainer.addChild(this._wheelFront, this._wheelRear);

    this.addCarGif('car');
    this.addCarGif('broken-car');
  }

  get view() {
    // eslint-disable-next-line no-underscore-dangle
    return this._view;
  }

  get wheelContainer() {
    // eslint-disable-next-line no-underscore-dangle
    return this._wheelContainer;
  }

  addCarGif(name) {
    const gifSpriteContainer = new Container();
    const gif = fetch(getAssetByName(name, 'sprites', assetsMap).url)
      .then((res) => res.arrayBuffer())
      .then(AnimatedGIF.fromBuffer)
      .then((image) => {
        image.position.set(-128, -144);
        image.height = 112;
        image.width = 255;

        const gifMask = new Graphics();
        gifMask.beginFill(0xFF0000);
        gifMask.drawRoundedRect(-127, -144, 255, 112, 100);
        gifMask.endFill();

        image.mask = gifMask;
        gifSpriteContainer.addChild(image, gifMask);
      });

    this._gifContainer.addChild(gifSpriteContainer);
  }

  wheelsStart() {
    this._wheelFront.rotation += 0.1;
    this._wheelRear.rotation += 0.1;
  }

  wheelsStop() {
    this._wheelFront.rotation = 0;
    this._wheelRear.rotation = 0;
  }

  brokenCar() {
    this._carContainer.addChild(this._brokenCar);
  }

  removeBrokenCar() {
    this._carContainer.removeChild(this._brokenCar);
  }

  removeCarImage() {
    this._carContainer.removeChild(this._car, this._mask);
  }

  endGame() {
    this._view.position.y -= 11;
    this._view.position.x += 11;
    this._view.rotation += 0.015;
  }

  carRespawning() {
    setTimeout(() => {
      this._view.alpha = 0.3;
    }, 300);

    setTimeout(() => {
      this._view.alpha = 1;
    }, 500);

    setTimeout(() => {
      this._view.alpha = 0.3;
    }, 700);

    setTimeout(() => {
      this._view.alpha = 1;
    }, 900);
  }

  darkTheme() {
    this._filter = new filters.ColorMatrixFilter();
    this._wheelContainer.filters = [this._filter];
    this._filter.negative(true);
  }

  whiteTheme() {
    this.wheelContainer.filters = [];
  }

  carLandingMoveDown() {
    if (this._view.children[1].position._y < 10) {
      this._view.children[1].position.y += 1;
    }
  }

  carLandingMoveUp() {
    if (this._view.children[1].position._y !== 0) {
      this._view.children[1].position.y -= 1;
    }
  }
}