class CaseMainPictureObject {
  constructor(container) {
    this.container = container;
    this.element = this.container.querySelector('[data-case-main-picture-parallax-object]');
    this.parallaxSpeed = this.element.dataset.speed;
    this.isMedium = window.innerWidth > 568 && window.innerWidth < 1025;
    this.isMobile = window.innerWidth < 569;

    this.setHeight();
    this.eventListeners();
    this.update();
  }

  setHeight() {
    this.height = this.container.getBoundingClientRect().height;
  }

  parallaxMove() {
    const posElemScreenTop = this.container.getBoundingClientRect().top;
    const posElemScreenBottom = this.container.getBoundingClientRect().bottom;

    const clientHeight = this.container.clientHeight;
    const fullHeight = window.innerHeight + clientHeight;

    const strafe = (clientHeight * this.parallaxSpeed) / 100;

    let strafeOffset = 0;
    if (this.isMedium) {
      strafeOffset = 20;
    } else if (this.isMobile) {
      strafeOffset = 20;
    }

    if (strafe > 0) {
      if (posElemScreenBottom > 0 && (posElemScreenTop > (0 - clientHeight))) {
        const offset = strafe * (1 - (Math.pow(posElemScreenBottom, 1.05) + clientHeight) / fullHeight) - strafeOffset;
        this.element.style.transform = `translate3d(0, ${offset}px, 0)`;
      }
    } else if (posElemScreenBottom > 0 && (posElemScreenTop > (0 - clientHeight))) {
      const offset = strafe * (1 - (posElemScreenTop + clientHeight) / fullHeight) - 10;
      this.element.style.transform = `translate3d(0, ${offset}px, 0)`;
    }
  }

  update() {
    this.parallaxMove();
  }

  eventListeners() {
    const instance = this;
    window.addEventListener('resize', () => {
      instance.setHeight();
    });

    document.addEventListener('DOMContentMutated', () => {
      instance.setHeight();
    });
  }
}

export default CaseMainPictureObject;