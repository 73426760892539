// scroll to target for smooth scroll
export default function scrollToTarget(target, offsetValue) {
  const top = target.offsetTop;
  const offset = parseInt(offsetValue, 10) || 0;
  const translate = top + offset;
  document.dispatchEvent(new CustomEvent('setScrollOffset', {
    detail: {
      offsetY: translate,
    },
  }));
}