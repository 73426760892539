import { throttle } from 'lodash';
import { onDomReady } from '../../components/dynamic/observer';

class Header {
  constructor(selector) {
    this.header = selector;
    this.headerContainer = this.header.querySelector('[data-header-container]');
    this.hamburger = this.header.querySelector('[data-header-hamburger]');
    this.menu = this.header.querySelector('[data-header-drop-menu]');

    this.footer = document.querySelector('[data-footer]');
    this.footerTop = undefined;

    this.lastScrollTop = 0;
    this.currentScrollTop = 0;

    this.headerHeight = this.header.offsetHeight;

    this.init();
    this.eventListeners();
    this.update();
  }

  init() {
    this.bindHamburger();
  }

  eventListeners() {
    const instance = this;
    document.addEventListener('scrolling', (e) => {
      instance.currentScrollTop = e.detail.offsetY;
    });

    window.addEventListener('resize', () => instance.onResize());
  }

  update() {
    if (this.currentScrollTop > this.header.offsetHeight) {
      this.header.classList.add('_sticky');

      if (this.currentScrollTop < this.lastScrollTop) {
        this.header.classList.add('_scroll-up');
      } else if (this.currentScrollTop > this.lastScrollTop) {
        this.header.classList.remove('_scroll-up');
      }
    } else {
      this.header.classList.remove('_sticky');
      this.header.classList.remove('_scroll-up');
    }

    this.updateLastScrollTopPosition();

    this.updateFooterPosition();
    this.translateHeaderByFooter();

    window.requestAnimationFrame(this.update.bind(this));
  }

  bindHamburger() {
    const instance = this;
    this.hamburger.addEventListener('click', throttle((e) => {
      e.preventDefault();
      if (instance.hamburger.classList.contains('_open')) {
        instance.closeMenu();
      } else {
        instance.openMenu();
      }
    }, 1000));
  }

  onResize() {
    this.headerHeight = this.header.getBoundingClientRect().height;
  }

  updateLastScrollTopPosition() {
    this.lastScrollTop = this.currentScrollTop;
  }

  updateFooterPosition() {
    if (this.footer) {
      this.footerTop = this.footer.getBoundingClientRect().top;
    }
  }

  translateHeaderByFooter() {
    const translateValue = this.headerHeight - this.footerTop;
    if (translateValue > 0) {
      this.headerContainer.style.transform = `translate3d(0, -${translateValue}px, 0)`;
    } else {
      this.translateHeaderContainerToDefault();
    }
  }

  translateHeaderContainerToDefault() {
    if (this.headerContainer.getBoundingClientRect().top !== 0) {
      this.headerContainer.style.transform = '';
    }
  }

  openMenu() {
    this.addClassOpen(this.hamburger);
    this.addClassOpen(this.menu);
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
  }

  closeMenu() {
    this.removeClassOpen(this.hamburger);
    this.removeClassOpen(this.menu);
    document.body.style.overflow = '';
    document.body.style.height = '';

    setTimeout(() => {
      this.menu.scrollTop = 0;
    }, 800);
  }

  addClassOpen(item) {
    if (!item.classList.contains('_close')) {
      item.classList.add('_open');
    }
  }

  removeClassOpen(item) {
    item.classList.remove('_open');
    if (!item.classList.contains('_open')) {
      this.addClassClose(item);
    }
    if (item.classList.contains('_close')) {
      setTimeout(() => {
        this.removeClassClose(item);
      }, 800);
    }
  }

  addClassClose(item) {
    item.classList.add('_close');
  }

  removeClassClose(item) {
    item.classList.remove('_close');
  }
}

onDomReady(() => {
  const header = document.querySelector('[data-header]');
  if (header) {
    new Header(header);
  }
});