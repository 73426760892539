import Scrollbar from 'smooth-scrollbar';
import { onDomReady } from '../../components/dynamic/observer';

class SmoothScrollDocument {
  constructor(selector) {
    this.element = selector;
    this.options = {
      damping: 0.22,
    };
    this.init();
    this.eventListeners();
  }

  init() {
    this.initScrollObject();
  }

  initScrollObject() {
    this.scrollObject = Scrollbar.init(this.element, this.options);
    this.dispatchScrollingEvent();
  }

  dispatchScrollingEvent() {
    const instance = this;
    this.scrollObject.addListener(() => {
      const event = new CustomEvent('scrolling', {
        detail: {
          offsetY: instance.scrollObject.offset.y,
        },
      });
      document.dispatchEvent(event);
    });
  }

  eventListeners() {
    document.addEventListener('setScrollOffset', (e) => {
      setTimeout(() => {
        this.scrollObject.scrollTo(0, e.detail.offsetY, 1000);
      }, 1000);
    });
  }
}

class SmoothScrollDocumentMobile {
  constructor() {
    this.offset = 0;
    this.scrollY = 0;

    document.addEventListener('scroll', () => {
      this.scrollY = window.pageYOffset;
    });

    this.update();
  }

  update() {
    this.offset = (1 - 0.5) * this.offset + 0.5 * this.scrollY;
    document.dispatchEvent(new CustomEvent('scrolling', {
      detail: {
        offsetY: this.offset,
      },
    }));

    window.requestAnimationFrame(this.update.bind(this));
  }
}

onDomReady(() => {
  if (window.innerWidth > 1024) {
    const element = document.querySelector('[data-smooth-scroll]');
    if (element) {
      new SmoothScrollDocument(element);
    }
  } else {
    new SmoothScrollDocumentMobile();
  }
});