require('../scss/app.scss');
// eslint-disable-next-line import/extensions
require('./svg/svg.js');

window.noZensmooth = true;

require('../fonts/RClass_Pragmatica/stylesheet.css');

require('./common/true-vh');
require('./header/header');
require('./common/goals2');
require('./common/js-validation');
require('./common/links');
require('./common/main');
require('./common/pagination');
require('./common/auto-pagination');
require('./common/respond');
require('./common/map');
require('./common/need-animation');
require('./common/webp-polyfill');
require('./common/follower');

require('./common/text-wrap-creator');

require('./accordion/initializator');
require('./accordion/switchable-accordion');

require('./field/phone');
require('./field/floating');
require('./field/custom-text-area');
// require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');

require('./switcher/switcher');

require('./sliders/sliders');

require('./scrolling-line/index');

require('./video/smart-pause');

require('./smooth-scroll/index');

require('./parallax/index');

require('./fading-transition/index');

require('./sliders/sliders');

require('./hoverHandler/index');

require('./game/game');
require('./custom-scroll-link/index');