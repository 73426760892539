import { onDomReady } from '../../components/dynamic/observer';

class ScrollingLine {
  constructor(selector) {
    this.line = selector;
    this.title = this.line.querySelector('[data-scroll-item]');

    this.scrollTop = window.pageYOffset;
    this.lastScrollTop = 0;
    this.offset = 0;
    this.ease = 0.35;
    this.speed = 1;

    this.init();
    this.eventListeners();
    this.update();
  }

  init() {
    this.calculateTitles();
  }

  calculateTitles() {
    if (this.line.offsetWidth < window.innerWidth + this.title.offsetWidth) {
      this.addTitleToLine();
      this.calculateTitles();
    }
  }

  addTitleToLine() {
    const title = this.title.cloneNode(true);
    this.line.append(title);
  }

  setStyles() {
    this.line.style.transform = `translate3d(${this.offset}px, 0, 0)`;
  }

  calculateOffset() {
    this.offset -= this.speed;
    this.offset += this.ease * (Math.abs(this.lastScrollTop - this.scrollTop) * -1);

    if (Math.abs(this.offset) >= this.title.offsetWidth) {
      this.offset = 0;
    }
  }

  eventListeners() {
    document.addEventListener('scrolling', (e) => {
      this.scrollTop = e.detail.offsetY;
    });

    window.addEventListener('resize', () => {
      this.init();
    });
  }

  update() {
    this.calculateOffset();
    this.setStyles();
    this.lastScrollTop = this.scrollTop;

    window.requestAnimationFrame(this.update.bind(this));
  }
}

onDomReady(() => {
  document.querySelectorAll('[data-scroll-line]').forEach((item) => {
    const scrollingLine = new ScrollingLine(item);
  });
});