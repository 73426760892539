import { onDomReady } from '../../components/dynamic/observer';

class Index {
  constructor(links) {
    this.links = links;
    this.layout = document.querySelector('.layout');
    // this.cursor = document.querySelector('[data-cursor]');
    this.bind();
  }

  bind() {
    this.links.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        // this.cursor.style.transition = 'opacity .4s var(--default-easing)';
        // this.cursor.style.opacity = '0';
        this.layout.classList.add('_animation-out');

        setTimeout(() => {
          window.location = link.getAttribute('href');
        }, 300);
      });
    });
  }
}

onDomReady(() => {
  const filterLinkByUrl = (link) => {
    const url = link.getAttribute('href');

    const isAnchor = /^#/g.test(url); // якоря
    const isModal = link.hasAttribute('data-modal'); // модалки
    const isAbsolute = /^\//g.test(url); // абсолютные урлы
    const valid = /^(ftp|http|https):\/\/[^ "]+$/g.test(url); // валидная ссылка
    const isFslightbox = link.hasAttribute('data-fslightbox'); // ссылки fslightbox
    const isTarget = link.hasAttribute('target'); // ссылки target blank

    if ((isAbsolute || valid) && !isModal && !isAnchor && !isFslightbox && !isTarget) {
      return link;
    }
  };

  const links = Array.from(document.querySelectorAll('a')).filter((link) => filterLinkByUrl(link));

  if (links) {
    new Index(links);
  }
});