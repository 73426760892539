// import PIXI from "https://pixijs.download/release/pixi.js";
// import * as PIXI from 'pixi.js';
import {
  Application, Graphics, Rectangle, Assets, Loader, Sprite, Container, Ticker, Texture, filters,
} from 'pixi.js';
import { assetsMap } from './assetsMap';
import { Car } from './car';
import { Sprites } from './blocks';

const container = document.querySelector('[data-game-container]');

if (container) {
  const startButton = document.querySelector('[data-game-start]');
  const timeBlock = document.querySelector('[data-game-time-block]');
  const timeBlockValue = document.querySelectorAll('[data-game-time-value]');
  const title = document.querySelector('[data-hide-title]');
  const backToMainButton = document.querySelector('[data-hide-button]');
  const gameOverBlock = document.querySelector('[data-game-over]');
  const restartButton = document.querySelector('[data-game-restart]');
  const taskCountValue = document.querySelector('[data-game-task-count]');
  const fixCountValue = document.querySelector('[data-game-fix-count]');
  const deadlineCountValue = document.querySelector('[data-game-deadline-count]');
  const roadLine = document.querySelector('[data-game-line]');
  const switcher = document.querySelector('[data-switch-button]');

  const containerWidth = container.parentElement.clientWidth;
  const containerHeight = container.parentElement.clientHeight;
  const screenWidth = window.screen.width;
  const halfWheelHeight = 22.5;
  let headerHeight = 0;

  // xs-large
  if (screenWidth > 1024 && screenWidth < 1441) {
    headerHeight = ((70 / 1440) * screenWidth);
  }

  // s-large and large
  if (screenWidth > 1440 && screenWidth < 1921) {
    headerHeight = 70;
  }

  // x-large
  if (screenWidth > 1920) {
    headerHeight = ((70 / 1920) * screenWidth);
  }

  const app = new Application({
    width: containerWidth,
    height: containerHeight,
    backgroundAlpha: 0,
    view: container,
  });

  let taskCount = 0;
  let fixCount = 0;
  let deadlineCount = 0;

  let isGameStarted = false;
  let isStarted = false;

  let spentTime = 0;
  let startDate = 0;
  let spentTimeSeconds = 0;

  let minSpawnTime = 0;
  let maxSpawnTime = 0;

  const car = new Car();
  car.view.children[1].children[0].visible = false;
  car.view.children[1].children[1].visible = false;
  const keys = {};

  let light = false;

  let isRotated = false;

  // спрайты (из набора из макета...)
  const sprites = new Sprites();
  // const spritesArrayLength = sprites.view.children.length;

  // блоки – из массива со спрайтами для добавления на страницу
  const blocks = [];

  let jumping = false;
  const axis = 'y';
  const direction = -1; // to Top
  const gravity = 0.8;
  const power = 20; // was 18
  const jumpAt = car.view[axis];
  let jumpPower = 15;

  function carLandingMoveDown() {
    car.carLandingMoveDown();
  }

  function carLandingMoveUp() {
    car.carLandingMoveUp();
  }

  function carLanding() {
    app.ticker.add(carLandingMoveDown);

    setTimeout(() => {
      app.ticker.remove(carLandingMoveDown);
      app.ticker.add(carLandingMoveUp);
    }, 200);

    setTimeout(() => {
      app.ticker.remove(carLandingMoveUp);
    }, 400);
  }

  const jump = (jumpPower) => {
    if (jumping) return;
    jumping = true;

    let time = 0;

    const tick = (deltaMs) => {
      const jumpHeight = (-gravity / 2) * Math.pow(time, 2) + jumpPower * time;

      if (jumpHeight < 0) {
        jumping = false;
        Ticker.shared.remove(tick);
        car.view[axis] = jumpAt;

        carLanding();

        return;
      }

      car.view[axis] = jumpAt + (jumpHeight * direction);
      time += deltaMs;
    };

    Ticker.shared.add(tick);
  };

  app.ticker.add(() => {
    if (switcher.classList.contains('_light')) {
      car.darkTheme();
      light = true;
    } else {
      car.whiteTheme();
    }
  });

  function addZero(time) {
    const score = 100000 + time;
    const scoreStr = score.toString();
    return scoreStr.slice(1);
  }

  // блок с рандомным содержимым
  function getRandomBlock(speed) {
    const randomNumber = Math.floor(Math.random() * 3);
    const sprite = sprites.spriteByIndex(randomNumber);
    const filter = new filters.ColorMatrixFilter();
    filter.negative(true);
    sprite.speed = speed;

    if (light) {
      sprite.filters = [filter];
    }

    app.stage.addChild(sprite);

    switch (randomNumber) {
      case 0:
        taskCountValue.innerHTML = ++taskCount;
        break;
      case 1:
        fixCountValue.innerHTML = ++fixCount;
        break;
      case 2:
        deadlineCountValue.innerHTML = ++deadlineCount;
        break;
    }

    return sprite;
  }

  // блок – создать
  function createBlock(blockSpeed) {
    const block = new Sprite(Texture.from('/static/video/game/word1.png'));
    block.anchor.set(0.5);
    block.height = 43;
    block.width = 87;
    block.x = containerWidth;
    block.y = 0;
    block.speed = blockSpeed;
    app.stage.addChild(block);

    return block;
  }

  // блок – показать
  function showBlock(sprite) {
    if (isGameStarted) {
      blocks.push(sprite);

      // console.log('blocks number: ', blocks.length);
    }
  }

  function normalScreen() {
    app.stage.angle = 0;
    app.stage.position.set(315, containerHeight - halfWheelHeight);
    app.stage.transform.scale.x = 1;
  }

  function rotateScreen(rotateDirection) {
    switch (rotateDirection) {
      case 0:
        // левый верхний угол
        normalScreen();
        app.stage.angle = 180;
        app.stage.y = halfWheelHeight + headerHeight;
        app.stage.transform.scale.x = -1;
        if (!roadLine.classList.contains('_rotated')) {
          roadLine.classList.add('_rotated');
        }
        break;
      case 1:
        // правый верхний угол
        normalScreen();
        app.stage.angle = 180;
        app.stage.y = halfWheelHeight + headerHeight;
        app.stage.transform.scale.x = 1;
        app.stage.x = containerWidth - 315;
        if (!roadLine.classList.contains('_rotated')) {
          roadLine.classList.add('_rotated');
        }
        break;
      case 2:
        // правый нижний угол
        normalScreen();
        app.stage.transform.scale.x = -1;
        app.stage.x = containerWidth - 315;
        if (roadLine.classList.contains('_rotated')) {
          roadLine.classList.remove('_rotated');
        }
        break;
      case 3:
        // левый нижний  угол
        normalScreen();
        if (roadLine.classList.contains('_rotated')) {
          roadLine.classList.remove('_rotated');
        }
        break;
    }
    isRotated = true;
  }

  // показать блоки - все, для текущей игры
  function showBlocks(timeDelta, elapsed) {
    // let timeDelta = 200;
    // let elapsed = 200; // сколько прожил последне-сгеннерированный блок

    maxSpawnTime = 120;
    minSpawnTime = 80;
    const blocks = () => {
      const tickBlock = (delta) => {
        elapsed += delta;
        if (isGameStarted) {
          spentTime = new Date().getTime() - startDate;
          spentTimeSeconds = Math.floor(spentTime / 1000);
          timeBlockValue.forEach((value) => {
            value.innerHTML = addZero(spentTime);
          });
          if (spentTimeSeconds > 32) {
            // правый верхний угол
            rotateScreen(1);
          }
          if (spentTimeSeconds > 65) {
            // правый нижний угол
            rotateScreen(2);
          }
          if (spentTimeSeconds > 98) {
            // левый верхний угол
            rotateScreen(0);
          }
          if (spentTimeSeconds > 131) {
            // левый нижний  угол
            rotateScreen(3);
          }
        }
        if (elapsed > timeDelta && isGameStarted) {
          // works now
          const speedByTime = spentTimeSeconds / 2;
          const sprite = getRandomBlock(10 + speedByTime);
          // works, but with uses one sprite
          // const sprite = createBlock(10);

          showBlock(sprite);

          elapsed = 0;
          // чуть уменьшим timeDelta, чтобы блоки ехали чуть плотнее. Но не слишком
          // if (timeDelta > 100) timeDelta -= Math.floor(Math.random() * 16);

          // space between blocks
          if (isGameStarted) {
            if (minSpawnTime > 45) {
              minSpawnTime = 80 - spentTimeSeconds;
            }

            if (maxSpawnTime > 70) {
              maxSpawnTime = 120 - spentTimeSeconds;
            }
          }

          timeDelta = minSpawnTime + Math.floor(Math.random() * maxSpawnTime);
          // console.log(`delta ${timeDelta}`);
          // console.log(`minSpawnTime ${minSpawnTime}`);
          // console.log(`maxSpawnTime ${maxSpawnTime}`);
        }
      };

      Ticker.shared.add(tickBlock);
      // Ticker.shared.remove(tickBlock);
    };

    blocks();
  }

  function deleteAllBlocks(blocksArray) {
    for (let i = 0; i < blocksArray.length; i++) {
      app.stage.removeChild(blocksArray[i]);
    }

    blocksArray.length = 0;
  }

  function deleteBlocks(blocksArray) {
    for (let i = 0; i < blocksArray.length; i++) {
      if (blocksArray[i].dead) {
        app.stage.removeChild(blocksArray[i]);
        blocksArray.splice(i, 1);
      }
    }
  }

  function updateBlocks() {
    for (let i = 0; i < blocks.length; i++) {
      blocks[i].position.x -= blocks[i].speed;

      if (blocks[i].position.x < -315) {
        blocks[i].dead = true;
      }
    }

    deleteBlocks(blocks);
  }

  function rectsIntersect(carItem, block) {
    const carSprite = carItem.getBounds();
    const blockSprite = block.getBounds();

    return carSprite.x + carSprite.width > blockSprite.x
            && carSprite.x < blockSprite.x + blockSprite.width
            && carSprite.y + carSprite.height > blockSprite.y
            && carSprite.y < blockSprite.y + blockSprite.height;
  }

  // прыжок
  function keysUpJump(e) {
    if (keys['38']) {
      keys[e.keyCode] = false;
      jump(jumpPower);
      jumpPower = 15;
    }
  }

  function keysDownJump(e) {
    keys[e.keyCode] = true;
    if (keys['38']) {
      const maxJumpHeight = 25;
      if (jumpPower < maxJumpHeight) {
        jumpPower++;
      } else {
        jumpPower = maxJumpHeight;
      }
    }
  }

  function keysDown(e) {
    keys[e.keyCode] = true;
    if (keys['38']) {
      startGame();
    }
  }

  function keysUp(e) {
    keys[e.keyCode] = false;
    window.removeEventListener('keydown', keysDown);
  }

  function restartGame() {
    car.removeBrokenCar();

    if (!isGameStarted) {
      setTimeout(() => {
        car.carRespawning();
      }, 500);
    }

    startGame();
  }

  function restartGameKeyboard(e) {
    keys[e.keyCode] = true;
    if (keys['38']) {
      keys[e.keyCode] = false;
      restartGame();
    }
  }

  function restartGameButton() {
    if (restartButton.click) {
      restartGame();
    }
  }

  function stopGame() {
    if (isGameStarted === false) {
      return;
    }
    isGameStarted = false;

    deleteBlocks(blocks);

    car.view.children[1].children[0].visible = false;
    car.view.children[1].children[1].visible = true;

    app.ticker.remove(gameLoop);

    app.ticker.add(endGame);
    app.ticker.remove(wheelsStart);

    if (!title.classList.contains('_game-over')) {
      title.classList.add('_game-over');
    }

    restartButton.addEventListener('click', restartGameButton);

    window.removeEventListener('keydown', keysDownJump);
    window.removeEventListener('keyup', keysUpJump);

    setTimeout(() => {
      window.addEventListener('keydown', restartGameKeyboard);
      window.addEventListener('keyup', keysUp);
    }, 500);

    // if (startButton.classList.contains('_hidden')) {
    //   startButton.classList.remove('_hidden');
    // }

    if (timeBlock.classList.contains('_visible')) {
      timeBlock.classList.remove('_visible');
    }

    if (!gameOverBlock.classList.contains('_visible')) {
      gameOverBlock.classList.add('_visible');
    }
  }

  function gameLoop() {
    updateBlocks();
    for (let i = 0; i < blocks.length; i++) {
      // если врезался, то все
      if (rectsIntersect(car.wheelContainer, blocks[i])) {
        stopGame();
        break;
      }
    }
  }

  function endGame() {
    car.endGame();
  }

  function wheelsStart() {
    car.wheelsStart();
  }

  function startGame() {
    if (isGameStarted === true) {
      return;
    }
    isGameStarted = true;
    spentTime = 0;

    fixCount = 0;
    taskCount = 0;
    deadlineCount = 0;

    minSpawnTime = 80;
    maxSpawnTime = 120;

    taskCountValue.innerHTML = fixCount;
    fixCountValue.innerHTML = taskCount;
    deadlineCountValue.innerHTML = deadlineCount;

    car.view.position.set(0, 0);
    car.view.rotation = 0;
    car.view.children[1].children[0].visible = true;
    car.view.children[1].children[1].visible = false;

    if (isRotated) {
      normalScreen();
      isRotated = false;
    }

    setTimeout(() => {
      car.removeCarImage();
    }, 500);

    startDate = new Date().getTime();

    const newDate = new Date();

    if (!isStarted) {
      showBlocks(200, 200);
      isStarted = true;
    }

    if (blocks.length > 0) {
      deleteAllBlocks(blocks);
    }

    app.ticker.add(gameLoop);
    app.ticker.remove(endGame);
    app.ticker.add(wheelsStart);

    window.removeEventListener('keydown', keysDown);
    window.removeEventListener('keyup', keysUp);
    setTimeout(() => {
      window.addEventListener('keydown', keysDownJump);
      window.addEventListener('keyup', keysUpJump);
    }, 100);

    app.ticker.add((delta) => {
      if (isGameStarted) {
        spentTime += delta;

        setTimeout(() => {
          car.removeCarImage();
        }, 500);
      } else {
        car.wheelsStop();
      }
    });

    if (roadLine.classList.contains('_rotated')) {
      roadLine.classList.remove('_rotated');
    }

    if (!startButton.classList.contains('_hidden')) {
      startButton.classList.add('_hidden');
    }

    if (!backToMainButton.classList.contains('_hidden')) {
      backToMainButton.classList.add('_hidden');
    }

    if (!timeBlock.classList.contains('_visible')) {
      timeBlock.classList.add('_visible');
    }

    if (!title.classList.contains('_animated')) {
      title.classList.add('_animated');
    }

    if (gameOverBlock.classList.contains('_visible')) {
      gameOverBlock.classList.remove('_visible');
    }
  }

  window.addEventListener('keyup', keysUp);
  window.addEventListener('keydown', keysDown);

  app.stage.addChild(car.view);
  app.stage.position.set(315, containerHeight - halfWheelHeight);
  window.CAR = car;
}