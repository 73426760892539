class FooterObject {
  constructor(container) {
    this.container = container;
    this.element = this.container.querySelector('[data-footer-parallax-object]');
    this.root = document.documentElement;
    if (window.innerWidth > 1024) {
      this.startOffset = -60;
    } else {
      this.startOffset = 0;
    }
    this.offset = this.startOffset;
    this.ratio = 0;

    this.setHeight();
    this.eventListeners();
    this.update();
  }

  setHeight() {
    this.height = this.container.getBoundingClientRect().height;
    this.root.style.setProperty('--footer-height', `${this.height}px`);
  }

  computedRatio() {
    const bounding = this.container.getBoundingClientRect();
    const { top } = bounding;
    const bottom = top + this.height;

    if (bounding.top > window.innerHeight) { // Контейнер целиком ниже экрана
      this.ratio = 1;
    } else if (this.height > window.innerHeight) { // Высота футера больше экрана
      // Определяем по верхней границе
      if (top <= 0) { // Верхняя граница выше экрана
        this.ratio = 0; // Закончили движение
      } else {
        this.ratio = top / window.innerHeight; // Вычисляем смещение
      }
    } else { // Высота футера меньше экрана
      // Определяем по нижней границе
      if (bottom <= window.innerHeight) { // Нижняя граница ниже экрана
        this.ratio = 0; // Закончили движение
      } else {
        this.ratio = (bottom - window.innerHeight) / this.height; // Вычисляем смещение
      }
    }
  }

  computedStyles() {
    this.offset = this.startOffset * this.ratio;
  }

  setStyles() {
    this.element.style.transform = `translate3d(0, ${this.offset}%, 0)`;
  }

  update() {
    this.computedRatio();
    this.computedStyles();
    this.setStyles();
  }

  eventListeners() {
    const instance = this;
    window.addEventListener('resize', () => {
      instance.setHeight();
    });

    document.addEventListener('DOMContentMutated', () => {
      instance.setHeight();
    });
  }
}

export default FooterObject;