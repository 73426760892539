function getAssetByName(name, type, assetMap) {
    for (const key in assetMap[type]) {
        const item = assetMap[type][key];
        if (item.name === name) {
            return item;
        }
    }
    return {};
}

export { getAssetByName };