import { onDomChanges, onDomReady } from '../../components/dynamic/observer';

class Follower {
  constructor(selector) {
    this.container = selector;
    this.object = this.container.querySelector('[data-follow-object]');

    this.cursor = {
      x: 0,
      y: 0,
      lastX: 0,
      lastY: 0,
    };

    this.ease = 1; // плавность (чем меньше тем более плавно будет)
    this.offsetValue = 25; // максимальное количество сдвига в пикселях
    this.direction = {
      x: undefined,
      y: undefined,
    };
    this.offset = {
      x: 0,
      y: 0,
    };

    this.updateContainerPosition();
    this.update();
    this.eventListeners();
  }

  setStyles() {
    this.object.style.transform = `translate3d(${this.offset.x}px, ${this.offset.y}px, 0)`;
  }

  defineDirection() {
    if (this.cursor.x < this.containerHorizontalCenter) {
      this.direction.x = 'left';
    } else if (this.cursor.x > this.containerHorizontalCenter) {
      this.direction.x = 'right';
    }

    if (this.cursor.y < this.containerVerticalCenter) {
      this.direction.y = 'up';
    } else if (this.cursor.y > this.containerVerticalCenter) {
      this.direction.y = 'down';
    }
  }

  handleMouseenterOnContainer() {
    if (
      this.cursor.x > this.containerLeft
      && this.cursor.x < this.containerRight
      && this.cursor.y > this.containerTop
      && this.cursor.y < this.containerBottom
    ) {
      this.defineDirection();
      this.computedOffset();
    } else {
      this.clearOffset();
    }
  }

  computedOffset() {
    const percent = (this.halfContainer / 100);

    if (this.direction.x === 'left') {
      const value = ((this.offsetValue * -1) * ((this.containerHorizontalCenter - this.cursor.x) / percent)) / 100;
      this.offset.x = (1 - this.ease) * this.offset.x + this.ease * value;
    } else if (this.direction.x === 'right') {
      const value = (this.offsetValue * ((this.cursor.x - this.containerHorizontalCenter) / percent)) / 100;
      this.offset.x = (1 - this.ease) * this.offset.x + this.ease * value;
    }

    if (this.direction.y === 'up') {
      const value = ((this.offsetValue * -1) * ((this.containerVerticalCenter - this.cursor.y) / percent)) / 100;
      this.offset.y = (1 - this.ease) * this.offset.y + this.ease * value;
    } else if (this.direction.y === 'down') {
      const value = (this.offsetValue * ((this.cursor.y - this.containerVerticalCenter) / percent)) / 100;
      this.offset.y = (1 - this.ease) * this.offset.y + this.ease * value;
    }
  }

  clearOffset() {
    this.offset.x = (1 - this.ease) * this.offset.x + this.ease * 0.1;
    this.offset.y = (1 - this.ease) * this.offset.y + this.ease * 0.1;
  }

  updateContainerPosition() {
    const bounds = this.container.getBoundingClientRect();
    this.containerWidth = this.container.offsetWidth;
    this.containerHeight = this.container.offsetHeight;
    this.containerLeft = bounds.left;
    this.containerRight = this.containerLeft + this.containerWidth;
    this.containerTop = bounds.top;
    this.containerBottom = this.containerTop + this.containerHeight;
    this.halfContainer = this.containerWidth / 2;

    this.containerHorizontalCenter = this.containerLeft + this.containerWidth / 2;
    this.containerVerticalCenter = this.containerTop + this.containerHeight / 2;
  }

  update() {
    this.handleMouseenterOnContainer();
    this.setStyles();

    this.cursor.lastX = this.cursor.x;
    this.cursor.lastY = this.cursor.y;

    window.requestAnimationFrame(this.update.bind(this));
  }

  eventListeners() {
    window.addEventListener('mousemove', (e) => {
      this.cursor.x = e.clientX;
      this.cursor.y = e.clientY;
    });

    document.addEventListener('scrolling', () => {
      this.updateContainerPosition();
    });
  }
}

function init() {
  const rootStyles = getComputedStyle(document.documentElement);
  const largeMinWidth = rootStyles.getPropertyValue('--large-min-width') || 1025;

  if (document.body.getBoundingClientRect().width >= largeMinWidth) {
    const containers = document.querySelectorAll('[data-follow-item]:not([data-initialize="true"])');
    containers.forEach((item) => {
      item.dataset.initialize = 'true';
      new Follower(item);
    });
  }
}

onDomReady(() => init());
onDomChanges(() => init());