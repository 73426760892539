export const assetsMap = {
  sprites: [
    { name: 'car', url: '/static/video/game/game.gif' },
    { name: 'broken-car', url: '/static/video/game/end-game.gif' },
    { name: 'wheel-front', url: '/static/video/game/wheel.png' },
    { name: 'wheel-rear', url: '/static/video/game/wheel.png' },
  ],
  words: [
    { name: 'word1', url: '/static/video/game/word1.png' },
    { name: 'word2', url: '/static/video/game/word2.png' },
    { name: 'word3', url: '/static/video/game/word3.png' },
  ],
};