import {EffectCreative, Navigation, Swiper} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  const container = document.querySelector('[data-review-slider-container]');
  if (container) {
    const sliderElement = container.querySelector('[data-review-slider]');
    const progressBar = container.querySelector('[data-review-progress-bar]');
    const left = container.querySelector('[data-review-left]');
    const right = container.querySelector('[data-review-right]');

    const slider = new Swiper(sliderElement, {
      modules: [Navigation, EffectCreative],
      speed: 1900,
      loop: true,
      grabCursor: true,
      preventInteractionOnTransition: true,
      effect: 'creative',
      creativeEffect: {
        prev: {
          translate: ['0%', 0, 0],
        },
        next: {
          translate: ['0%', 0, 0],
        },
      },
      navigation: {
        prevEl: left,
        nextEl: right,
      },
      on: {
        init(swiper) {
          setTimeout(() => {
            const slideHeight = swiper.$wrapperEl[0].offsetHeight;
            swiper.slides.forEach((slide) => {
              slide.style.height = `${slideHeight}px`;
            });
          }, 2000);
        },
      },
    });

    const myEndFunction = () => {
      slider.slideNext(1900, true);
      progressBar.style.animation = 'none';
      void progressBar.offsetWidth; // Triggers Reflow
      progressBar.style.animation = null;
    };

    slider.on('slideChange', () => {
      progressBar.style.animation = 'none';
      void progressBar.offsetWidth; // Triggers Reflow
      progressBar.style.animation = null;
    });

    progressBar.addEventListener('animationend', myEndFunction);
  }
});