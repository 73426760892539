import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  const container = document.querySelector('[data-team-block]');
  if (container) {
    const elements = container.querySelectorAll('[data-autoplay-video]');

    // stop all video
    elements.forEach((element) => {
      element.pause();
    });

    // play visible videos on scroll
    document.addEventListener('scrolling', (e) => {
      elements.forEach((element) => {
        const { top } = element.getBoundingClientRect();
        const height = 0 - element.clientHeight;
        if (top < window.innerHeight && top > height) {
          window.requestAnimationFrame(() => {
            element.play();
          });
        } else {
          element.pause();
        }
      });
    });
  }
});