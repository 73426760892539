/**
 *  Использование в стилях:
 *  height: calc(var(--vh, 1vh) * 100);
 *  width: calc(var(--vh, 1vw) * 100);
 *
 *  Поймать событие:
 *  document.addEventListener("VhVarUpdate", ()=>{});
 *
 *  Android, Chrome - срабатывает при отпускания пальца от экрана
 *  IOS, Safari - срабатывает во время скролла
 *
 */
function setVhVar() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  const event = new Event('VhVarUpdate');
  document.dispatchEvent(event);
}

document.addEventListener('DOMContentLoaded', setVhVar);
window.addEventListener('resize', setVhVar);

/**
 * Вы не поверите, но по ширине такая же история, в частности с браузерами на винде
 *
 * width: calc(var(--vw, 1vw) * 100);
 *
 */

function setVwVar() {
  const vw = document.documentElement.clientWidth / 100;
  document.documentElement.style.setProperty('--vw', `${vw}px`);
  const event = new Event('VwVarUpdate');
  document.dispatchEvent(event);
}

document.addEventListener('DOMContentLoaded', setVwVar);
window.addEventListener('resize', setVwVar);