import { EffectCreative, Navigation, Pagination, Swiper } from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

const init = () => {
  const containerEl = document.querySelector('[data-article-image-slider-container]');

  if (!containerEl) {
    return;
  }

  const sliderEl = containerEl.querySelector('[data-article-image-slider]');
  const leftArrow = containerEl.querySelector('[data-article-image-slider-left]');
  const rightArrow = containerEl.querySelector('[data-article-image-slider-right]');

  const slider = new Swiper(sliderEl, {
    modules: [Navigation, Pagination, EffectCreative],
    loop: true,
    speed: 1500,
    preventInteractionOnTransition: true,
    effect: 'creative',
    navigation: {
      prevEl: leftArrow,
      nextEl: rightArrow,
    },
    pagination: {
      el: containerEl.querySelector('.swiper-pagination'),
      type: 'fraction',
    },
    creativeEffect: {
      prev: {
        translate: ['-20%', 0, -1],
      },
      current: {
        translate: [0, 0, 0],
      },
      next: {
        translate: ['100%', 0, 1],
      },
    },
  });
};

onDomReady(() => init());