import { onDomReady } from '../../components/dynamic/observer';
import scrollToTarget from './scroll-to-target';

class CustomScrollLink {
  constructor() {
    this.handleQueryString();
    this.getTargetFromQuery();
    if (this.target) {
      scrollToTarget(this.target);
    }
  }

  handleQueryString() {
    const queryString = window.location.search;
    console.log(queryString);
    this.urlParams = new URLSearchParams(queryString);
  }

  getTargetFromQuery() {
    this.targetId = this.urlParams.get('targetId');
    this.target = document.querySelector(`${this.targetId}`);
  }
}

onDomReady(() => {
  new CustomScrollLink();
});