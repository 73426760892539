import { onDomReady } from '../../components/dynamic/observer';
import FooterObject from './objects/FooterObject';
import TextRepetitionObject from './objects/TextRepetitionObject';
import StickyObject from './objects/StickyObject';
import CaseMainPictureObject from './objects/CaseMainPictureObject';

class Parallax {
  constructor() {
    this.offsetY = 0;
    this.lastOffsetY = 0;

    this.initObjects();
    this.eventListeners();
    this.update();
  }

  initObjects() {
    this.initTextRepetitions();
    this.initStickyObjects();
    this.initFooter();
    this.initCaseMainPicture();
  }

  initTextRepetitions() {
    this.textRepetitionItems = [];

    document.querySelectorAll('[data-text-repetition]')
      .forEach((item) => {
        this.textRepetitionItems.push(new TextRepetitionObject(item));
      });
  }

  initStickyObjects() {
    this.stickyObjects = [];

    document.querySelectorAll('[data-sticky-container]')
      .forEach((item) => {
        this.stickyObjects.push(new StickyObject(item));
      });
  }

  initFooter() {
    const footerContainerElement = document.querySelector('[data-footer-parallax-container]');
    if (footerContainerElement) {
      this.footerObject = new FooterObject(footerContainerElement);
    }
  }

  initCaseMainPicture() {
    this.CaseMainPictureObjects = [];

    document.querySelectorAll('[data-case-main-picture-parallax-container]')
      .forEach((item) => {
        this.CaseMainPictureObjects.push(new CaseMainPictureObject(item));
      });
  }

  update() {
    if (this.lastOffsetY !== this.offsetY) {
      this.textRepetitionItems.forEach((item) => item.update(this.offsetY));

      this.stickyObjects.forEach((item) => item.update());

      this.CaseMainPictureObjects.forEach((item) => item.update());

      this.footerObject.update();

      this.lastOffsetY = this.offsetY;
    }

    window.requestAnimationFrame(this.update.bind(this));
  }

  eventListeners() {
    const instance = this;

    document.addEventListener('scrolling', (event) => {
      instance.offsetY = event.detail.offsetY;
    });
  }
}

onDomReady(() => {
  const parallax = new Parallax();
});