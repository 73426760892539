import { onDomReady } from '../../components/dynamic/observer';
import axios from "axios";

async function setBgId(data) {
  try {
    return axios.post('/api/set_bg_id', data, {});
  } catch (e) {
    console.log(e);
    return false;
  }
}

const init = () => {
  const layout = document.querySelector('[data-layout]');

  document.querySelectorAll('[data-switch-button]').forEach((button) => {
    button.addEventListener('click', async () => {
      let data;
      if (parseInt(button.dataset.switchButton, 10) === 10) {
        data = { id: 20 };

        try {
          await setBgId(data).then(() => {
            button.dataset.switchButton = '20';
            button.classList.add('_light');
            layout.classList.add('_light');
          });
        } catch (e) {
          console.log(e);
        }
      } else if (parseInt(button.dataset.switchButton, 10) === 20) {
        data = { id: 10 };

        try {
          await setBgId(data).then(() => {
            button.dataset.switchButton = '10';
            button.classList.remove('_light');
            layout.classList.remove('_light');
          });
        } catch (e) {
          console.log(e);
        }
      }
    });
  });
};

onDomReady(() => init());