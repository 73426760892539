export default class StickyObject {
  constructor(selector) {
    this.container = selector;
    this.element = selector.querySelector('[data-sticky-block]');

    this.header = document.querySelector('[data-header]');
    this.headerHeight = this.header.offsetHeight;
  }

  computedStyles() {
    const containerBounds = this.container.getBoundingClientRect();

    if (containerBounds.top > this.headerHeight) {
      this.translate = 0;
    } else if (containerBounds.top < this.headerHeight
            && containerBounds.bottom - this.element.offsetHeight - this.headerHeight > 0) {
      this.translate = (containerBounds.top * -1) + this.headerHeight;
    } else {
      this.translate = this.container.offsetHeight - this.element.offsetHeight;
    }
  }

  setStyles() {
    this.element.style.transform = `translate3d(0, ${this.translate}px, 0)`;
  }

  update() {
    if (this.container.offsetHeight > this.element.offsetHeight) {
      this.computedStyles();
      this.setStyles();
    }
  }
}