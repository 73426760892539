import { onDomChanges, onDomReady } from '../../components/dynamic/observer';

function init() {
  document.querySelectorAll('[data-custom-text-area] textarea')
    .forEach((textarea) => {
      const field = textarea.closest('[data-custom-text-area]');
      if (field.dataset.initialized) {
        return;
      }
      field.dataset.initialized = 'true';
      textarea.rows = 1;

        textarea.addEventListener('keyup', () => {
        if (textarea.value) {
          field.classList.add('_filled');
        } else {
          field.classList.remove('_filled');
        }
      });

      textarea.style.height = `${textarea.scrollHeight}px`;
      textarea.style.overflowY = 'hidden';
        textarea.addEventListener('input', () => {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
      });
    });
}

onDomReady(() => init());
onDomChanges(() => init());