// Работает только со свайпером.

const sliderCounter = (slider, count, total) => {
  const slidesIndexText = count;
  const slidesCountText = total;

  let slidesIndex = slider.activeIndex + 1;
  let slidesCount = slider.snapGrid.length;

  // Если ширина слайдера меньше ширины экрана прогрессбар и счетчик dispay:none;
  if (slidesCount <= 1) {
    count.closest('.slider-nav').style.display = 'none';
    return;
  }

  if (slidesIndexText && slidesCountText) {
    slidesIndexText.innerHTML = slidesIndex < 10 ? `0${slidesIndex}` : slidesIndex;
    slidesCountText.innerHTML = slidesCount < 10 ? `0${slidesCount}` : slidesCount;

    slider.on('snapIndexChange', () => {
      slidesIndex = slider.activeIndex + 1;
      slidesCount = slider.snapGrid.length;
      slidesIndexText.innerHTML = slidesIndex < 10 ? `0${slidesIndex}` : slidesIndex;
      slidesCountText.innerHTML = slidesCount < 10 ? `0${slidesCount}` : slidesCount;
    });
  }
};

export default sliderCounter;