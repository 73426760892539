class HoverHandler {
  constructor(selector) {
    this.container = selector;
    this.list = this.container.querySelector('[data-hovered-list]');
    this.inAnimationTime = parseInt(this.list.dataset.inAnimationTime, 10) ?? 0;
    this.outAnimationTime = parseInt(this.list.dataset.outAnimationTime, 10) ?? 0;
    this.items = this.container.querySelectorAll('[data-hovered-item]');

    this.eventListeners();
  }

  eventListeners() {
    this.list.addEventListener('mouseleave', () => {
      this.items.forEach((item) => {
        item.classList.remove('_hovered');
        item.classList.remove('_anim-in-playing');
        item.classList.remove('_anim-out-playing');
      });
    });

    this.items.forEach((item) => {
      item.addEventListener('mouseenter', () => {
        item.classList.add('_hovered');
        item.classList.remove('_anim-out-playing');
        item.classList.add('_anim-in-playing');
        this.inStartTime = new Date();
        setTimeout(() => {
          item.classList.remove('_anim-in-playing');
        }, this.inAnimationTime);
      });

      item.addEventListener('mouseleave', () => {
        item.classList.remove('_hovered');
        if (!item.classList.contains('_anim-in-playing')) {
          item.classList.add('_anim-out-playing');
          setTimeout(() => {
            item.classList.remove('_anim-out-playing');
          }, this.outAnimationTime);
        } else {
          this.elapsed = new Date() - this.inStartTime;
          setTimeout(() => {
            item.classList.add('_anim-out-playing');
            setTimeout(() => {
              item.classList.remove('_anim-out-playing');
            }, this.outAnimationTime);
          }, (this.inAnimationTime - this.elapsed));
        }
      });
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-hovered-container]').forEach((item) => {
    new HoverHandler(item);
  });
});